body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: env(safe-area-inset-top);
    padding-bottom: 0; /* 하단 패딩 제거 */
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    min-height: 100vh; /* 최소 높이를 뷰포트 높이로 설정 */
    box-sizing: border-box; /* 패딩을 높이에 포함 */
}

/* 필요한 경우, 마지막 요소에 추가 여백을 줄 수 있습니다 */
body > :last-child {
    margin-bottom: env(safe-area-inset-bottom);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
